import React, { useCallback, useEffect } from "react";

import "./App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import { Spinner } from "./spinner/Spinner";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");

const ANALYTICS_URL = "https://analytics.theofficeenglishlearning.com/";

const sendAnalytics = async (data, userId, meetingId) => {
  const response = await fetch(ANALYTICS_URL, {
    method: "POST",
    body: JSON.stringify({
      title: "Zoom Meeting Error",
      data,
      userId,
      meetingId,
    }),
  });
};

function App() {
  const [isLoading, setLoading] = React.useState(true);
  const [userName, setUserName] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [noParams, setNoParams] = React.useState(false);
  const [userRole, setRole] = React.useState(undefined);
  const [signature, setSignature] = React.useState("");
  const params = new URLSearchParams(window.location.search);
  const meetingId = params.get("meetingId");
  const userId = params.get("userId");
  console.log(meetingId);

  useEffect(() => {
    if (!meetingId || !userId) {
      setNoParams(true);
    }
  }, [meetingId, userId]);

  // setup your Meeting SDK auth endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  const authEndpoint =
    "https://zlbda3z5hj.execute-api.us-east-1.amazonaws.com/default/zoom-meeting-signer";
  const sdkKey = "V3_rMku5QlGhqmUwNRuNbw";
  const leaveUrl = "https://plataforma.theofficeenglishlearning.com";

  const startMeeting = useCallback(
    (signature) => {
      document.getElementById("zmmtg-root").style.display = "block";

      ZoomMtg.i18n.load("es-ES");
      ZoomMtg.init({
        leaveUrl: leaveUrl,
        enableHD: true,
        isLockBottom: false,
        showMeetingHeader: false,
        // isShowJoiningErrorDialog: false,
        success: (success) => {
          console.log(success);

          ZoomMtg.join({
            signature: signature,
            sdkKey: sdkKey,
            meetingNumber: meetingId,
            userName: userName ?? "User",
            userEmail: userEmail ?? "",
            passWord: "",
            customerKey: userId,
            success: (success) => {
              console.log(success);
            },
            error: async (error) => {
              await sendAnalytics(error, userId, meetingId);
            },
          });
        },
        error: async (error) => {
          await sendAnalytics(error, userId, meetingId);
        },
      });
    },
    [leaveUrl, sdkKey, meetingId, userName, userId, userEmail]
  );

  const getSignature = useCallback(
    (userRole) => {
      try {
        const authEndpointUrl = new URL(authEndpoint);
        authEndpointUrl.searchParams.append("meetingId", meetingId);
        authEndpointUrl.searchParams.append("role", userRole.toString());

        fetch(authEndpointUrl.toString(), {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((response) => {
            setSignature(response.signature);
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    },
    [authEndpoint, meetingId]
  );

  const fetchUser = useCallback(async () => {
    if (noParams) {
      setLoading(false);
      return;
    }

    const url = new URL(
      "https://lvbttvxw93.execute-api.us-east-1.amazonaws.com/default/zoom-liveclass-user-fetcher"
    );
    url.searchParams.append("userId", userId);
    const response = await fetch(url.toString());
    const data = await response.json();

    setUserName(data.name);
    setUserEmail(data.email);
    setRole(data.role);
  }, [userId, noParams]);

  useEffect(() => {
    if (meetingId && userRole !== undefined) {
      getSignature(userRole);
    }
  }, [getSignature, meetingId, userRole]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (signature) {
      startMeeting(signature);
    }
  }, [signature, startMeeting]);

  useEffect(() => {
    if (noParams) {
      window.location.href = "https://plataforma.theofficeenglishlearning.com";
    }
  }, [noParams]);

  if (isLoading) {
    return <Spinner />;
  }

  return <></>;
}

export default App;
